import React from 'react'
import { MdOutlineLockClock } from 'react-icons/md'

export default function EmptyStore() {
	return (
		<div className='min-h-screen w-full justify-center pt-40 flex'>
			<div className='flex-col flex items-center'>
				<MdOutlineLockClock className='text-9xl text-gray-300' />
				<p className='text-center'>
					Your account could be blocked, please contact admin
				</p>
			</div>
		</div>
	)
}
