import React from 'react'
import huawei from '../assets/huawei.png'
import apple from '../assets/apple.png'
import android from '../assets/google.png'

export default function MobileLinks() {
	return (
		<div className='w-screen flex-col text-sm flex items-center'>
			<h1>Havillah Elite app is now available on:</h1>
			<div className='flex space-x-3 mt-3 mb-5'>
				<a
					target='_blank'
					rel='noreferrer'
					href='https://play.google.com/store/apps/details?id=com.havillahbeauty.app'>
					<img
						src={android}
						alt='android_logo'
						className='h-8 object-contain'
					/>
				</a>
				<a
					target='_blank'
					rel='noreferrer'
					href='https://apps.apple.com/us/app/havillah-elite/id1581844363'>
					<img src={apple} alt='android_logo' className='h-8 object-contain' />
				</a>
				<a
					target='_blank'
					rel='noreferrer'
					href='https://appgallery.huawei.com/app/C104618567'>
					<img src={huawei} alt='android_logo' className='h-8 object-contain' />
				</a>
			</div>
		</div>
	)
}
