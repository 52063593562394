import HeaderComponent from './HeaderComponent'

export default function Support() {
	return (
		<div className='min-h-screen'>
			<HeaderComponent
				leadText='Frequently Asked Questions'
				subText='Find support and assistance'
			/>
		</div>
	)
}
