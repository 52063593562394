import { IoArrowBackCircle } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

export default function HeaderNav({ title }) {
	const navigation = useNavigate()
	return (
		<>
			<div className='p-3 bg-black flex justify-center items-center relative'>
				<button
					onClick={() => navigation(-1)}
					className='flex items-center text-gray-300'>
					<div className='flex items-center left-2 absolute'>
						<IoArrowBackCircle className='text-xl mr-1' />
						<p>Back</p>
					</div>
				</button>
				<p className='text-white'>{title}</p>
			</div>
		</>
	)
}
