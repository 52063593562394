import { useNavigate } from 'react-router-dom'
import { IoMdArrowRoundBack } from 'react-icons/io'

export default function HeaderComponent({ leadText, subText }) {
	const navigation = useNavigate()

	return (
		<div>
			<div className='bg-gray-300 flex p-1'>
				<button
					onClick={() => navigation(-1)}
					className='items-center flex left-5'>
					<IoMdArrowRoundBack className='text-lg bg-black text-white rounded-full p-1 h-5 w-5' />
					<span className='ml-2'>Back</span>
				</button>
			</div>
			<div className='bg-black text-white flex justify-center p-2 text-xl flex-col items-center'>
				<p>{leadText}</p>
				<p className='text-sm mt-1'>{subText}</p>
			</div>
		</div>
	)
}
