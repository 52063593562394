import React from 'react'
import { AiOutlineFileAdd } from 'react-icons/ai'
export default function EmptyOrder() {
	return (
		<div className='min-h-screen w-full justify-center pt-40 flex'>
			<div className='flex flex-col items-center'>
				<AiOutlineFileAdd className='text-9xl text-gray-300' />
				<p>You have no order records</p>
			</div>
		</div>
	)
}
