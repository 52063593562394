import React from 'react'
import { IoChatbubbleEllipsesOutline } from 'react-icons/io5'

export default function EmptyChat() {
	return (
		<div className='min-h-screen w-full justify-center pt-40 flex'>
			<div className='flex-col flex items-center'>
				<IoChatbubbleEllipsesOutline className='text-9xl text-gray-300' />
				<p>You have no messages</p>
			</div>
		</div>
	)
}
