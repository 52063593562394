import HeaderComponent from './HeaderComponent'

export default function Privacy() {
	return (
		<div className='min-h-screen'>
			<HeaderComponent
				leadText='Privacy Policy'
				subText='Havillah Elite terms of use'
			/>
		</div>
	)
}
