import { AiOutlineClockCircle } from 'react-icons/ai'

export default function AwaitConfirmation() {
	return (
		<div className='w-full flex flex-col items-center'>
			<p className='flex items-center justify-center text-white w-full py-1 bg-amber-500'>
				Your payment hasn't been approved yet
			</p>
			<AiOutlineClockCircle className='text-9xl mt-5 text-gray-400' />
			<p>Orders are processed within 48hours</p>
		</div>
	)
}
