import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { getPackaging } from '../context/slices/order-slice'
import logo from '../assets/magic.png'

export default function PackagingModal() {
	const dispatch = useDispatch()
	const [plastics, setPlastics] = useState(10)
	const [boxes, setBoxes] = useState(5)
	const [plasticPrice, setPlasticPrice] = useState(50)
	const [boxPrice, setBoxPrice] = useState(50)

	const handlePlastics = () => {
		dispatch(
			getPackaging({
				packaging: true,
				amount: plasticPrice,
				content: `Plastic Bags x ${plastics}`,
			})
		)
	}
	const handleBoxes = () => {
		dispatch(
			getPackaging({
				packaging: true,
				amount: boxPrice,
				content: `Branded Boxes x ${boxes}`,
			})
		)
	}
	const addBox = () => {
		setBoxes(prevState => prevState + 1)
		setBoxPrice(prevState => prevState + 10)
	}
	const addPlastics = () => {
		setPlastics(prevState => prevState + 1)
		setPlasticPrice(prevState => prevState + 5)
	}
	const removeBox = () => {
		if (boxes === 5) return
		setBoxes(prevState => prevState - 1)
		if (boxPrice === 50) return
		setBoxPrice(prevState => prevState - 10)
	}
	const removePlastics = () => {
		if (plastics === 10) return
		setPlastics(prevState => prevState - 1)
		if (plasticPrice === 50) return
		setPlasticPrice(prevState => prevState - 5)
	}
	return (
		<div className='modalOverlay'>
			<div className='modalCover'>
				<div className='bg-gray-300 p-2 rounded-t-md'>Select Packaging</div>
				<div className='p-5 flex-col flex items-center'>
					<div className='w-28 h-28 rounded-full items-center justify-center flex mb-3 border-2 border-y-green-600'>
						<img
							className='w-24 h-24 rounded-full'
							src={logo}
							alt='packaging'
						/>
					</div>
					<p className='text-xs'>Please note that packaging is compulsory</p>
					<p className='text-xs'>
						Select the right packaging option for your products
					</p>
					<div className='my-2 bg-gray-200 py-1 w-full flex justify-evenly items-center'>
						<div className='text-sm'>Plastic Bags</div>
						<div className='flex border-2 bg-gray-100 py-1 px-3 rounded-full justify-between'>
							<button onClick={removePlastics} className='w-5'>
								-
							</button>
							<p>{plastics}</p>
							<button onClick={addPlastics} className='w-5'>
								+
							</button>
						</div>
						<div className='text-sm'>R {plasticPrice}</div>
						<button
							onClick={handlePlastics}
							className='bg-pink-500 text-white px-3 py-1 rounded-xl'>
							Add
						</button>
					</div>
					<div className='my-2 bg-gray-200 py-1 w-full flex justify-evenly items-center'>
						<div className='text-sm'>Branded Boxes</div>
						<div className='flex border-2 bg-gray-100 py-1 px-3 rounded-full justify-between'>
							<button onClick={removeBox} className='w-5'>
								-
							</button>
							<p>{boxes}</p>
							<button onClick={addBox} className='w-5'>
								+
							</button>
						</div>
						<div className='text-sm'>R {boxPrice}</div>
						<button
							onClick={handleBoxes}
							className='bg-pink-500 text-white px-3 py-1 rounded-xl'>
							Add
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}
