import React from 'react'

export default function ConfirmWaybill({
	confirmPhoto,
	stage,
	handleApprove,
	handleReject,
}) {
	return (
		<div className='items-center w-full flex flex-col'>
			<p className='flex items-center justify-center text-white py-1 bg-pink-600 w-full'>
				Confirm delivery address
			</p>
			<div className='w-72 h-72 bg-gray-200 rounded-md my-5'>
				{confirmPhoto && (
					<img
						src={confirmPhoto}
						alt='pic'
						className='w-72 h-72 object-contain'
					/>
				)}
			</div>
			<p className='text-sm'>Confirm if the delivery address</p>
			<p className='text-sm'>on the waybill is correct.</p>
			<div className='flex space-x-2 mb-2'>
				<button
					onClick={() => handleApprove(stage)}
					className='submitBtn bg-green-500'>
					Approve
				</button>
				<button onClick={handleReject} className='submitBtn bg-red-500'>
					Reject
				</button>
			</div>
		</div>
	)
}
