import React, { useEffect, useState } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import { IoAddCircleOutline, IoRemoveCircleOutline } from 'react-icons/io5'
import { BsCheckCircle, BsCheckCircleFill } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedItem } from '../context/slices/order-slice'
import * as categories from '../data/store_options'
import { useFirestore } from '../hooks/useFirestore'

export default function SelectItemsModal({ setListedItems }) {
	const { product } = useSelector(state => state.order.selectProduct.product)
	const dispatch = useDispatch()
	const [selectedItem, setSelectedItem] = useState([])
	const [pickedItem, setPickedItem] = useState([])
	const { updateDocument } = useFirestore('myProducts')

	const { firestoreId } = product

	const newProducts = selectedItem.filter(item => item.quantity > 0)

	const closeModal = () => {
		dispatch(
			getSelectedItem({
				product: {},
				openModal: false,
			})
		)
	}

	const selectorButton = id => {
		if (pickedItem.includes(id)) {
			setPickedItem(prevState => prevState.filter(item => item !== id))
		} else {
			setPickedItem(prevState => [...prevState, id])
		}
	}

	const decrementButton = index => {
		const newItems = [...selectedItem]
		newItems[index].quantity--
		setSelectedItem(newItems)
	}
	const incrementButton = index => {
		const newItems = [...selectedItem]
		newItems[index].quantity++
		setSelectedItem(newItems)
	}

	const calculateTotalQuantity = newProducts.reduce(
		(total, item) => total + item.quantity,
		0
	)

	const updateSelectedItem = () => {
		updateDocument(firestoreId, { quantity: calculateTotalQuantity })
		setListedItems(prev => [...prev, ...newProducts])
		closeModal()
	}

	useEffect(() => {
		;(() => {
			if (product.palletteShade) {
				setSelectedItem(categories.palletteShade)
				return
			}

			if (product.shirt) {
				setSelectedItem(categories.shirt)
				return
			}

			if (product.golfSize) {
				setSelectedItem(categories.golfSize)
				return
			}

			if (product.makeupShade) {
				setSelectedItem(categories.makeupShade)
				return
			}

			if (product.lipGloss) {
				setSelectedItem(categories.lipGloss)
				return
			}
			if (product.eyebrowPencil) {
				setSelectedItem(categories.eyebrowPencil)
				return
			}

			if (product.lipstickColor) {
				setSelectedItem(categories.lipstickColor)
				return
			}

			if (product.bonzerShade) {
				setSelectedItem(categories.bonzerShade)
				return
			}

			if (product.capSize) {
				setSelectedItem(categories.capSize)
				return
			}
		})()
	}, [firestoreId, product])

	return (
		<div className='modalOverlay'>
			<div className='modalCover'>
				<div className='bg-gray-300 px-4 py-2 w-full rounded-t-md flex justify-between items-center'>
					<p>You have selected {calculateTotalQuantity} items</p>
					<button onClick={closeModal}>
						<AiFillCloseCircle className='text-3xl' />
					</button>
				</div>
				<div className='mb-5'>
					{selectedItem.map((item, index) => (
						<div
							key={item.id}
							className='border-b p-2 flex items-center justify-between'>
							<div className='flex space-x-1'>
								<button
									onClick={() => selectorButton(item.col)}
									className='text-xl'>
									{pickedItem.includes(item.col) ? (
										<BsCheckCircleFill className='text-green-500' />
									) : (
										<BsCheckCircle />
									)}
								</button>
								<p>{item.col}</p>
							</div>
							<div
								className={`flex items-center space-x-2 text-xl ${
									!pickedItem.includes(item.col) && 'text-gray-300'
								}`}>
								<button
									onClick={() => decrementButton(index)}
									disabled={!pickedItem.includes(item.col)}>
									<IoRemoveCircleOutline className='text-2xl' />
								</button>
								<p>{item.quantity}</p>
								<button
									onClick={() => incrementButton(index)}
									disabled={!pickedItem.includes(item.col)}>
									<IoAddCircleOutline className='text-2xl' />
								</button>
							</div>
						</div>
					))}
				</div>
				<div onClick={updateSelectedItem} className='submitBtn mb-5'>
					Submit
				</div>
			</div>
		</div>
	)
}
