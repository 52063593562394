import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import HeaderNav from '../../components/HeaderNav'
import { useFirestore } from '../../hooks/useFirestore'
import RejectedOrder from './RejectedOrder'
import OrderProgress from './OrderProgress'
import RejectedPayment from './RejectedPayment'
import PendingOrder from './PendingOrder'
import AwaitConfirmation from './AwaitConfirmation'
import ConfirmWaybill from './ConfirmWaybill'

export default function OrderDetails() {
	const { id } = useParams()
	const { orders } = useSelector(state => state.order)
	const { updateDocument } = useFirestore('orders')
	const userOrder = orders.filter(order => order.id === id)
	const navigation = useNavigate()

	const handleApprove = stage => {
		updateDocument(id, { stage: stage + 1, confirmed: true }).then(() =>
			setTimeout(() => navigation(-1), 1500)
		)
	}

	const handleReject = () => {
		updateDocument(id, {
			confirmed: false,
			confirmPhoto: null,
			isRejected: true,
			photos: [],
		}).then(() => setTimeout(() => navigation(-1), 1500))
	}

	return (
		<div className='min-h-screen'>
			<HeaderNav title='Order status' />
			{userOrder?.map(order => (
				<div className='flex flex-col' key={order.id}>
					<div className='p-1 text-sm'>
						<p className='bg-gray-200 my-1'>
							ORDER PAYMENT:{' '}
							<span
								className={`${
									order.orderStatus === 'Approved' && 'text-green-500'
								}`}>
								{order.orderStatus}
							</span>
						</p>
						<p className='bg-gray-200 my-1'>ORDER AMOUNT: R {order.bagTotal}</p>
						<p className='bg-gray-200 my-1'>PACKAGING: {order.branding}</p>
						<p className='bg-gray-200 my-1'>
							DELIVERY ADDRESS: {order.address}
						</p>
					</div>
					{order.confirmed === true && order.stage > 1 && (
						<OrderProgress stage={order.stage} id={id} />
					)}
					{order.confirmed === 'Pending' && order.stage === 3 && (
						<ConfirmWaybill
							confirmPhoto={order.confirmPhoto}
							stage={order.stage}
							handleApprove={handleApprove}
							handleReject={handleReject}
						/>
					)}

					{order.confirmPhoto === null && order.orderStatus === 'Approved' && (
						<RejectedOrder />
					)}
					{order.photos === undefined && order.orderStatus === 'Approved' && (
						<PendingOrder />
					)}
					{order.orderStatus === 'Rejected' && <RejectedPayment />}
					{!order.confirmed && order.orderStatus === 'Pending' && (
						<AwaitConfirmation />
					)}
					{order.confirmed === 'Pending' && order.stage === 1 && (
						<>
							<p className='flex items-center justify-center text-white w-full py-1 bg-pink-600'>
								Packed order items
							</p>
							<div className='p-1'>
								{order.orders.map((item, index) => (
									<div key={item.id} className='w-full px-1 bg-gray-100 my-1'>
										<div className='flex justify-between'>
											<div className='flex'>
												<p>
													{index + 1}.{'\xa0'}{' '}
												</p>
												<p>{item.name}</p>
											</div>
											<p>x {item.quantity}</p>
										</div>
									</div>
								))}
								{order.orderStatus === 'Approved' && order.confirmPhoto && (
									<>
										<p className='text-sm mt-3'>Confirm attached images:</p>
										<div className='flex bg-gray-200 p-2 rounded-sm'>
											<p className='text-xs'>
												NB: The images show products that will be dispatched to
												you, please confirm if your order is correct and it will
												be dispatched.
											</p>
										</div>
										<div className='flex mb-2'>
											<button
												onClick={() => handleApprove(order.stage)}
												className='submitBtn bg-green-500'>
												Approve
											</button>
											<button
												onClick={handleReject}
												className='submitBtn bg-red-500'>
												Reject
											</button>
										</div>
										<div className='flex'>
											{order.photos?.map(photo => (
												<img
													key={photo}
													src={photo}
													alt='pic'
													className='flex flex-wrap border w-32 h-32 rounded-md m-1 object-cover justify-center items-center'
												/>
											))}
										</div>
									</>
								)}
							</div>
						</>
					)}
				</div>
			))}
		</div>
	)
}
