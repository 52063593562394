import { RiAlertFill } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import { alertsReducer } from '../context/slices/user-slice'

export default function AlertModal() {
	const errorMessage = useSelector(state => state.user.alertMessage)
	const dispatch = useDispatch()

	const clearError = () => {
		dispatch(alertsReducer(null))
	}

	return (
		<div className='modalOverlay'>
			<div className='modalCover'>
				<div className='bg-gray-300 flex justify-center items-center p-2 rounded-t-md'>
					<RiAlertFill
						className={`text-2xl ${
							errorMessage.includes('Success!')
								? 'text-green-500'
								: 'text-red-500'
						} mr-2`}
					/>
					{errorMessage.includes('Success!')
						? 'Success!'
						: 'Oops! Something went wrong'}
				</div>
				<div className='p-5 text-center'>{errorMessage}</div>
				<button onClick={clearError} className='submitBtn mb-5'>
					Okay
				</button>
			</div>
		</div>
	)
}
