import { useEffect, useState } from 'react'
import { useCollection } from '../hooks/useCollection'
import Product from '../components/Product'
import Modal from '../components/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { getMyProducts, getProducts } from '../context/slices/product-slice'
import { getUserOrders } from '../context/slices/order-slice'
import {
	collection,
	onSnapshot,
	orderBy,
	query,
	where,
} from 'firebase/firestore'
import { db } from '../firebase/config'
import AlertModal from '../components/AlertModal'
import { alertsReducer } from '../context/slices/user-slice'
import LoadingModal from '../components/LoadingModal'
import EmptyStore from '../components/EmptyStore'

export default function Store() {
	const { documents: products } = useCollection('products')
	const [shownProducts, setShownProducts] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const dispatch = useDispatch()
	const { open } = useSelector(state => state.products.isOpen.open)
	const { group, randomUserID } = useSelector(state => state.user?.activeUser)
	const userId = useSelector(state => state.user.userId)
	const errorMessage = useSelector(state => state.user.alertMessage)

	const eliteGroup = products?.filter(item => item.elite && !item.isDeleted)
	const makeupGroup = products?.filter(item => item.makeup && !item.isDeleted)
	const salesGroup = products?.filter(item => item.salesRep && !item.isDeleted)

	useEffect(() => {
		setIsLoading(true)
		if (products && group) {
			if (group === 'salesRep') {
				setShownProducts(salesGroup)
			} else if (group === 'elite') {
				setShownProducts(eliteGroup)
			} else if (group === 'makeup') {
				setShownProducts(makeupGroup)
			} else if (group === null) {
				setShownProducts(null)
			}
			setIsLoading(false)
		}
		setIsLoading(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [group, products, dispatch])

	useEffect(() => {
		if (userId) {
			;(async () => {
				let ref = collection(db, 'myProducts')
				ref = query(ref, where('userId', '==', userId))
				try {
					onSnapshot(ref, snapshot => {
						let results = []
						snapshot.docs.forEach(doc => {
							results.push({ id: doc.id, ...doc.data() })
						})
						dispatch(getMyProducts(results))
						dispatch(getProducts(shownProducts))
					})
				} catch (error) {
					console.log(error)
					dispatch(alertsReducer(error.message))
				}
			})()
		}
	}, [dispatch, shownProducts, userId])

	useEffect(() => {
		if (!userId) return
		;(async () => {
			let ref = collection(db, 'orders')
			ref = query(
				ref,
				where('randomUserID', '==', randomUserID),
				orderBy('timestamp', 'desc')
			)
			try {
				onSnapshot(ref, snapshot => {
					let results = []
					snapshot.docs.forEach(doc => {
						results.push({
							id: doc.id,
							listedItems: doc.data().listedItems,
							confirmed: doc.data().confirmed,
							confirmPhoto: doc.data().confirmPhoto,
							branding: doc.data().branding,
							bagTotal: doc.data().bagTotal,
							address: doc.data().address,
							confirmAddress: doc.data().confirmAddress,
							dateCreated: doc.data().dateCreated,
							monthCreated: doc.data().monthCreated,
							orderNumber: doc.data().orderNumber,
							orders: doc.data().orders,
							photos: doc.data().photos,
							paymentProof: doc.data().paymentProof,
							randomUserID: doc.data().randomUserID,
							stage: doc.data().stage,
							orderStatus: doc.data().status,
							timeCreated: doc.data().timeCreated,
							yearCreated: doc.data().yearCreated,
						})
					})
					dispatch(getUserOrders(results))
				})
			} catch (error) {
				console.log(error)
				dispatch(alertsReducer(error.message))
			}
		})()
	}, [userId, dispatch, randomUserID])

	if (isLoading || !shownProducts.length) {
		return (
			<div className='min-h-screen'>
				<LoadingModal />
			</div>
		)
	}
	return (
		<div className='min-h-screen'>
			<div className='grid grid-cols-3 place-items-center items-end p-1 space-x-1 space-y-1'>
				{shownProducts?.map(item => (
					<Product key={item.id} product={item} />
				))}
			</div>
			{!shownProducts && !errorMessage && <EmptyStore />}
			{errorMessage && <AlertModal />}
			{open && <Modal />}
		</div>
	)
}
