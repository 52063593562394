import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EmptyCart from '../components/EmptyCart'
import { IoRepeatOutline, IoBackspaceOutline } from 'react-icons/io5'
import PackagingModal from '../components/PackagingModal'
import CartItem from '../components/CartItem'
import { useFirestore } from '../hooks/useFirestore'
import { increment } from 'firebase/firestore'
import { getPackaging } from '../context/slices/order-slice'
import SelectItemsModal from '../components/SelectItemsModal'
import { finalOrderItems } from '../context/slices/order-slice'
import { useNavigate } from 'react-router-dom'

export default function Cart() {
	const {
		packaging,
		amount: packagingCost,
		content,
	} = useSelector(state => state.order.packaging)
	const dispatch = useDispatch()
	const [subtotal, setSubtotal] = useState(0)
	const [mustDeliver, setMustDeliver] = useState(true)
	const [listedItems, setListedItems] = useState([])
	const [trash, setTrash] = useState([])
	const { updateDocument, deleteDocument } = useFirestore('myProducts')
	const products = useSelector(state => state.products.products)
	const myProducts = useSelector(state => state.products.myProducts)
	const navigation = useNavigate()

	const { openModal } = useSelector(
		state => state.order.selectProduct.openModal
	)

	const initialCartContent = products.filter(
		item => myProducts.map(item => item.productID).includes(item.id) === true
	)

	//This function adds the firestoreId and quantity to the items in myProducts
	//So I may have access to these values when making calculations in calculateDiscount()
	const addDeltaToBase = (delta, base) =>
		base.map(({ id, ...rest }) => {
			const foundIt = delta.find(({ productID }) => productID === id)
			if (foundIt) {
				return {
					id,
					...rest,
					quantity: foundIt.quantity,
					firestoreId: foundIt.id,
				}
			}
			return { id, ...rest }
		})
	const cartProducts = addDeltaToBase(myProducts, initialCartContent)

	useEffect(
		() =>
			(() => {
				const prices = []
				cartProducts.forEach(item => {
					if (item.quantity === item.minQuantity)
						return prices.push(item.price * item.minQuantity)

					if (
						item.quantity > item.minQuantity &&
						item.quantity < item.descQuantity
					)
						return prices.push(item.price * item.quantity)

					if (item.quantity < item.minQuantity)
						return prices.push(item.quantity * item.unitPrice)

					if (item.quantity >= item.descQuantity) {
						const discountQuantity = item.discPrice
							? item.discPrice
							: item.price
						return prices.push(item.quantity * discountQuantity)
					}
				})

				const total = prices.reduce(
					(acc, amount) => acc + parseFloat(amount),
					0
				)
				setSubtotal(total)
			})(),
		[cartProducts, myProducts]
	)

	const deliveryFee = mustDeliver ? 100 : 0
	const bagTotal = subtotal + deliveryFee + packagingCost

	const calculateDiscount = item => {
		if (item.quantity === item.minQuantity) return item.price * item.minQuantity

		if (item.quantity > item.minQuantity && item.quantity < item.descQuantity)
			return item.price * item.quantity

		if (item.quantity < item.minQuantity) return item.quantity * item.unitPrice

		if (item.quantity >= item.descQuantity) {
			const discountQuantity = item.discPrice ? item.discPrice : item.price
			return item.quantity * discountQuantity
		}
	}

	const addQuantity = id => {
		updateDocument(id, { quantity: increment(1) })
		if (trash.includes(id)) {
			setTrash(prevState => prevState.filter(item => item !== id))
		}
	}

	const removeQuantity = (id, quantity) => {
		if (quantity > 1) {
			updateDocument(id, { quantity: increment(-1) })
		} else {
			setTrash(prevState => [...prevState, id])
		}
	}
	const deleteProduct = id => {
		deleteDocument(id)
	}

	const openPackagingModal = () => {
		dispatch(
			getPackaging({
				packaging: false,
				amount: null,
				content: null,
			})
		)
	}

	const openDeliveryAlert = () => {
		setMustDeliver(!mustDeliver)
	}

	const checkout = () => {
		dispatch(
			finalOrderItems({
				bagTotal: bagTotal,
				cartContent: cartProducts,
				branding: content,
				listedItems: listedItems,
			})
		)
		navigation('/address')
	}

	return (
		<div className='min-h-screen'>
			{!cartProducts.length ? (
				<EmptyCart />
			) : (
				<>
					<div className=''>
						{cartProducts.map(product => (
							<CartItem
								key={product.id}
								product={product}
								addQuantity={addQuantity}
								calculateDiscount={calculateDiscount}
								removeQuantity={removeQuantity}
								deleteProduct={deleteProduct}
								trash={trash}
							/>
						))}
					</div>
					<div className='fixed bottom-14 w-full border rounded-t-2xl bg-gray-200 p-5'>
						<div className='flex justify-between items-center border-b border-gray-100 py-1'>
							<p>Subtotal</p>
							<p>R {subtotal}</p>
						</div>
						<div className='flex justify-between items-center border-b border-gray-100 py-1'>
							<button
								onClick={openPackagingModal}
								className='flex items-center space-x-2'>
								<p>{content}</p>
								<IoRepeatOutline className='text-xl text-red-500' />
							</button>
							<p>R {packagingCost}</p>
						</div>
						<div className='flex justify-between items-center border-b border-gray-100 py-1'>
							<button
								onClick={openDeliveryAlert}
								className='flex items-center space-x-2'>
								<p>Delivery</p>
								<IoBackspaceOutline className='text-xl text-red-500' />
							</button>
							<p>R {deliveryFee}</p>
						</div>
						<div className='flex justify-between items-center border-b border-gray-100 py-1'>
							<p>Bag Total</p>
							<p>R {bagTotal}</p>
						</div>
						<button onClick={checkout} className='submitBtn'>
							<p>Checkout</p>
						</button>
					</div>
				</>
			)}
			{!packaging && cartProducts.length ? <PackagingModal /> : null}
			{openModal && <SelectItemsModal setListedItems={setListedItems} />}
		</div>
	)
}
