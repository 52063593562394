import { HiOutlineChevronRight } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import EmptyOrder from '../../components/EmptyOrder'

export default function Orders() {
	const { orders } = useSelector(state => state.order)

	return (
		<div className='min-h-screen'>
			<div className='border p-2 m-5 bg-gray-100 border-pink-600 rounded-md items-center flex flex-col'>
				<h1 className='text-2xl'>Delivery Notice</h1>
				<p>Orders are dispatched weekdays</p>
				<p>between 08:00 and 16:00</p>
				<p>excl. weekends and holidays</p>
			</div>
			{!orders?.length ? (
				<EmptyOrder />
			) : (
				<div className='flex w-full flex-col  '>
					<p className='flex justify-center bg-pink-500 py-1 text-white mb-1'>
						Your latest orders
					</p>
					{orders.map(order => (
						<Link
							to={order.id}
							key={order.id}
							className='flex items-center justify-between hover:bg-slate-100 p-1 border mb-1'>
							<div className=''>
								<p>Order no. {order.orderNumber}</p>
								<p className='text-xs text-gray-400'>
									Created: {order.dateCreated}
								</p>
							</div>
							<HiOutlineChevronRight className='text-2xl' />
						</Link>
					))}
				</div>
			)}
		</div>
	)
}
