import { increment } from 'firebase/firestore'
import { AiFillCheckCircle } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { useFirestore } from '../../hooks/useFirestore'

export default function OrderProgress({ stage, id }) {
	const { updateDocument } = useFirestore('orders')
	const navigation = useNavigate()
	const handleSubmit = e => {
		e.preventDefault()
		updateDocument(id, { stage: increment(1) }).then(() => navigation(-1))
	}

	return (
		<div className='items-center w-full flex flex-col'>
			<p className='flex items-center justify-center text-white py-1 bg-pink-600 w-full'>
				Order processing stages
			</p>
			<div className=''>
				<div className='flex mt-3 items-center space-x-2'>
					<AiFillCheckCircle
						className={`text-xl ${
							stage >= 2 ? 'text-green-500' : 'text-gray-400'
						}`}
					/>
					<p>Order payment is approved</p>
				</div>
				<div className='flex items-center space-x-2'>
					<AiFillCheckCircle
						className={`text-xl ${
							stage >= 3 ? 'text-green-500' : 'text-gray-400'
						}`}
					/>
					<p>Packaged products approved</p>
				</div>
				<div className='flex items-center space-x-2'>
					<AiFillCheckCircle
						className={`text-xl ${
							stage >= 4 ? 'text-green-500' : 'text-gray-400'
						}`}
					/>
					<p>Delivery address waybill approved</p>
				</div>
				<div className='flex items-center space-x-2'>
					<AiFillCheckCircle
						className={`text-xl ${
							stage >= 5 ? 'text-green-500' : 'text-gray-400'
						}`}
					/>
					<p>Order dispatched from warehouse</p>
				</div>
				<div className='flex items-center space-x-2'>
					<AiFillCheckCircle
						className={`text-xl ${
							stage >= 6 ? 'text-green-500' : 'text-gray-400'
						}`}
					/>
					<p>Confirm receipt of correct order</p>
				</div>
			</div>
			{stage === 5 && (
				<button onClick={handleSubmit} className='submitBtn'>
					Order arrived
				</button>
			)}
		</div>
	)
}
