import React from 'react'
import { useDispatch } from 'react-redux'
import { openModal } from '../context/slices/product-slice'

export default function Product({ product }) {
	const dispatch = useDispatch()

	return (
		<button
			onClick={() => dispatch(openModal({ open: true, content: product }))}
			className='max-w-xs border bg-slate-100 rounded-md'>
			<img src={product.image} alt={product.name} />
			<div className='bg-white rounded-b-md pt-1'>
				<p className='text-xs'>{product.name}</p>
				<p className='font-extrabold text-xl'>R {product.price}</p>
			</div>
		</button>
	)
}
