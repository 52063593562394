import React from 'react'
import { IoAdd } from 'react-icons/io5'
import EmptyChat from '../../components/EmptyChat'

export default function Messages() {
	return (
		<div className='relative'>
			<button className='fixed bottom-20 right-20 bg-pink-500 h-24 rounded-full w-24 items-center justify-center flex'>
				<IoAdd className='text-6xl text-pink-800' />
			</button>
			<EmptyChat />
		</div>
	)
}
