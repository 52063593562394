import React from 'react'
import { GiShoppingBag } from 'react-icons/gi'
export default function EmptyCart() {
	return (
		<div className='min-h-screen w-full justify-center pt-40 flex'>
			<div className='flex-col flex items-center'>
				<GiShoppingBag className='text-9xl text-gray-300' />
				<p>You have no items in your cart</p>
			</div>
		</div>
	)
}
