import { BsBagCheckFill } from 'react-icons/bs'

export default function PendingOrder() {
	return (
		<div className='items-center flex-col justify-center flex w-full'>
			<p className='flex items-center justify-center text-white w-full py-1 bg-green-500'>
				Order payment has been approved
			</p>
			<div className='my-5 bg-gray-200 p-5 items-center flex flex-col text-sm rounded-md'>
				<BsBagCheckFill className='text-3xl my-2 mx-auto text-green-500' />
				<p>Your payment has been approved</p>
				<p>Your order is being processed</p>
			</div>
		</div>
	)
}
