import { useState } from 'react'
import { sendPasswordResetEmail } from 'firebase/auth'
import { auth } from '../firebase/config'
import { useDispatch, useSelector } from 'react-redux'
import { alertsReducer } from '../context/slices/user-slice'
import AlertModal from '../components/AlertModal'
import { Link } from 'react-router-dom'

export default function ResetPassword() {
	const [email, setEmail] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState(null)
	const errorMessage = useSelector(state => state.user.alertMessage)
	const dispatch = useDispatch()

	const handleSubmit = async e => {
		e.preventDefault()
		setIsLoading(true)
		setError(null)
		try {
			await sendPasswordResetEmail(auth, email)
			dispatch(
				alertsReducer('Success! check your email for the password reset link.')
			)
		} catch (error) {
			setIsLoading(false)
			if (error.message.includes('user-not-found')) {
				setError('That email is not registered')
			} else if (error.message.includes('invalid-email')) {
				setError('Your have entered an invalid email')
			} else if (error.message.includes('network-request-failed')) {
				setError('Check your data and network connection')
			} else {
				setError(error.message)
			}
		}
	}

	return (
		<div className='flex flex-col mx-auto my-20 px-5 py-10 max-w-md rounded-sm bg-white shadow-md border border-gray-200'>
			{errorMessage && <AlertModal />}
			<h2 className='text-2xl mb-3'>Reset Password</h2>
			<form onSubmit={handleSubmit} className='flex flex-col'>
				<label className='flex flex-col space-y-1 my-2'>
					<span>email:</span>
					<input
						className='border p-1 rounded-md outline-none'
						type='email'
						required
						onChange={e => setEmail(e.target.value)}
						value={email}
					/>
				</label>
				{error && (
					<p className='text-sm text-red-800 text-center my-2'>{error}</p>
				)}
				<button className='px-7 py-1 rounded-md border-pink-500 border-2 self-center'>
					{isLoading ? 'Loading...' : 'Send'}
				</button>
			</form>
			<Link to='/' className='self-center text-gray-500 text-sm pt-5'>
				Login to your account
			</Link>
		</div>
	)
}
