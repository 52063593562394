import { useState } from 'react'
import { GoAlert } from 'react-icons/go'
import { storage, timestamp } from '../../firebase/config'
import { useFirestore } from '../../hooks/useFirestore'
import { v4 as uuid } from 'uuid'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { useNavigate, useParams } from 'react-router-dom'

export default function RejectedPayment() {
	const [thumbnailError, setThumbnailError] = useState(null)
	const [selectedImage, setSelectedImage] = useState(null)
	const [previewImage, setPreviewImage] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const { updateDocument } = useFirestore('orders')
	const navigation = useNavigate()
	const { id } = useParams()

	const handleSubmit = e => {
		e.preventDefault()
		setThumbnailError(null)
		setIsLoading(true)

		const obj = {
			createdAt: timestamp,
			timeCreated: Date.now(),
			timestamp: timestamp,
			paymentProof: selectedImage,
			status: 'Pending',
			confirmed: false,
		}

		if (!selectedImage) {
			setIsLoading(false)
			setThumbnailError('Please select an image')
			return
		}
		try {
			updateDocument(id, obj)
				.then(() => setIsLoading(false))
				.finally(() => navigation(-1))
		} catch (error) {
			setIsLoading(false)
			setThumbnailError(error.message)
		}
	}

	const handleFileChange = async e => {
		setIsLoading(true)
		setPreviewImage(null)
		setSelectedImage(null)
		setThumbnailError(null)
		let selected = e.target.files[0]

		if (!selected) {
			setIsLoading(false)
			setThumbnailError('Please upload an image first')
			return
		}
		if (selected.size > 1100000) {
			setIsLoading(false)
			setThumbnailError('Image size must be less than 1MB')
			return
		}

		const imagePathRef = ref(storage, `payments/${selected.name + uuid()}`)
		await uploadBytesResumable(imagePathRef, selected)
		const url = await getDownloadURL(imagePathRef)
		setSelectedImage(url)

		const reader = new FileReader()
		reader.addEventListener('load', () => {
			setPreviewImage(reader.result)
		})
		reader.readAsDataURL(selected)
		setIsLoading(false)
	}

	return (
		<div className='flex flex-col justify-center w-full'>
			<p className='flex items-center justify-center text-white w-full py-1 bg-red-500'>
				Order payment has been rejected
			</p>
			<div className='w-56 flex-col my-2 text-xs self-center rounded-md text-center p-2 text-white bg-red-500'>
				{!previewImage ? (
					<>
						<GoAlert className='text-3xl my-2 mx-auto' />
						<p className='text-xl mb-5 text-black'>
							This order has been Rejected.
						</p>
						<p>Re-submit your proof of payment.</p>
						<br />
						<p>Alternatively WhatsApp this no.</p>
						<p>084 **** 400</p>
					</>
				) : (
					<img src={previewImage} alt='payment_pic' />
				)}
			</div>
			<div className='w-56 self-center mt-5'>
				<input type='file' required onChange={handleFileChange} />
				{thumbnailError && (
					<p className='text-xs text-red-500 my-2'>{thumbnailError}</p>
				)}
				<button
					disabled={isLoading}
					className='submitBtn'
					onClick={handleSubmit}>
					{isLoading ? 'Loading' : 'Submit'}
				</button>
			</div>
		</div>
	)
}
