import HeaderComponent from './HeaderComponent'

export default function AccountDetails() {
	return (
		<div className='min-h-screen'>
			<HeaderComponent
				leadText='Your account details'
				subText='Verify and update your information'
			/>
		</div>
	)
}
