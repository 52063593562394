import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	products: [],
	myProducts: [],
	isOpen: {
		open: false,
		content: null,
	},
}
const productSlice = createSlice({
	name: 'products',
	initialState,
	reducers: {
		getProducts(state, action) {
			state.products = action.payload
		},
		getMyProducts(state, action) {
			state.myProducts = action.payload
		},
		openModal(state, action) {
			state.isOpen = {
				open: action.payload,
				content: action.payload,
			}
		},
	},
})

export const { getProducts, getMyProducts, openModal } = productSlice.actions

export default productSlice.reducer
