import { signOut } from 'firebase/auth'
import { useDispatch } from 'react-redux'
import { signOutReducer } from '../context/slices/user-slice'
import { auth } from '../firebase/config'

export const useLogout = () => {
	const dispatch = useDispatch()

	const logout = async () => {
		try {
			await signOut(auth)
			dispatch(signOutReducer())
		} catch (error) {
			console.log(error.message)
		}
	}

	return { logout }
}
