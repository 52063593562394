import { useEffect, useState } from 'react'
import { auth } from '../firebase/config'
import {
	signInWithEmailAndPassword,
	sendEmailVerification,
} from 'firebase/auth'
import { useDispatch } from 'react-redux'
import { authReducer, alertsReducer } from '../context/slices/user-slice'

export const useLogin = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [isCancelled, setIsCancelled] = useState(false)
	const [error, setError] = useState(null)

	const dispatch = useDispatch()

	const sendVerificationEmail = async (user, email) => {
		setIsLoading(true)
		try {
			await sendEmailVerification(user)

			dispatch(
				alertsReducer(
					`Success! an email has been sent to ${email}, click the link to verify your account`
				)
			)
			setIsLoading(false)
		} catch (error) {
			setError(error.message)
		}
		setIsLoading(false)
	}

	const login = async (email, password) => {
		setError(null)
		setIsLoading(true)

		try {
			const response = await signInWithEmailAndPassword(auth, email, password)
			if (response.user.emailVerified) {
				dispatch(authReducer())
				return
			} else {
				setError('Your email is not yet verified')
				sendVerificationEmail(auth.currentUser, email)
			}
			setIsLoading(false)
			if (!isCancelled) {
				setIsLoading(false)
				setError(null)
			}
		} catch (error) {
			setIsLoading(false)
			if (!isCancelled) {
				setIsLoading(false)
				if (error.message && error.message.includes('wrong-password')) {
					setError('Password error!... Your have entered an invalid password')

					return
				} else if (error.message && error.message.includes('user-not-found')) {
					setError('Email error!... Your have entered an invalid email')

					return
				} else if (
					error.message &&
					error.message.includes('network-request-failed')
				) {
					setError('Network error!... Check your data and network connection')
					return
				} else {
					setError(error.message)
					return
				}
			}
		}
	}
	// this is a clean up function to prevent
	// unmounted state components from being rendered
	useEffect(() => {
		return () => setIsCancelled(true)
	}, [])
	return { login, isLoading, error }
}
