import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Cart from './pages/Cart'
import Store from './pages/Store'
import Orders from './pages/Orders'
import SignIn from './pages/SignIn'
import Profile from './pages/Profile'
import Payment from './pages/Payment'
import Messages from './pages/Message'
import NavBar from './components/Navbar'
import Footer from './components/Footer'
import OrderDetails from './pages/Orders/OrderDetails'
import { useEffect, useState } from 'react'
import { auth, db } from './firebase/config'
import Privacy from './pages/Profile/Privacy'
import Support from './pages/Profile/Support'
import { doc, getDoc } from 'firebase/firestore'
import AlertModal from './components/AlertModal'
import ResetPassword from './pages/ResetPassword'
import { onAuthStateChanged } from 'firebase/auth'
import LoadingModal from './components/LoadingModal'
import { useDispatch, useSelector } from 'react-redux'
import AccountDetails from './pages/Profile/AccountDetails'
import AddressConfirmation from './pages/AddressConfirmation'
import {
	authReducer,
	activeUserReducer,
	alertsReducer,
} from './context/slices/user-slice'

function App() {
	const [isAuthenticated, setIsAuthenticated] = useState(false)
	const isAuthReady = useSelector(state => state.user.isAuthReady)
	const errorMessage = useSelector(state => state.user.alertMessage)
	const [isLoading, setIsLoading] = useState(true)
	const dispatch = useDispatch()

	useEffect(() => {
		const subscribe = onAuthStateChanged(auth, user => {
			if (user && user.emailVerified) {
				try {
					dispatch(authReducer({ userId: user.uid }))
					const docRef = doc(db, 'userDetails', user.uid)
					const fetchData = async () => {
						const docData = await getDoc(docRef)
						dispatch(activeUserReducer(docData.data()))
					}
					fetchData()
						.then(() => setIsAuthenticated(true))
						.finally(() => setIsLoading(false))
				} catch (error) {
					console.log(error.message)
					dispatch(alertsReducer(error.message))
					setIsLoading(false)
					return
				}
			} else {
				setIsAuthenticated(false)
				setIsLoading(false)
			}
		})
		return subscribe
	}, [dispatch, isAuthenticated, isAuthReady])

	useEffect(() => {
		dispatch(authReducer())
	})

	if (isLoading && !errorMessage) {
		return <LoadingModal />
	}
	if (errorMessage && !isLoading) {
		return <AlertModal />
	}

	return (
		<BrowserRouter>
			{isAuthReady && (
				<div className='bg-gray-50'>
					<NavBar />
					<Routes>
						<Route
							path='/'
							element={isAuthenticated ? <Store /> : <Navigate to='/login' />}
						/>
						<Route
							path='/login'
							element={isAuthenticated ? <Navigate to='/' /> : <SignIn />}
						/>
						<Route
							path='/reset'
							element={
								isAuthenticated ? <Navigate to='/' /> : <ResetPassword />
							}
						/>
						<Route
							path='/messages'
							element={
								isAuthenticated ? <Messages /> : <Navigate to='/login' />
							}
						/>
						<Route
							path='/orders'
							element={isAuthenticated ? <Orders /> : <Navigate to='/login' />}
						/>
						<Route
							path='/orders/:id'
							element={
								isAuthenticated ? <OrderDetails /> : <Navigate to='/login' />
							}
						/>
						<Route
							path='/profile'
							element={isAuthenticated ? <Profile /> : <Navigate to='/login' />}
						/>
						<Route
							path='/cart'
							element={isAuthenticated ? <Cart /> : <Navigate to='/login' />}
						/>
						<Route
							path='/address'
							element={
								isAuthenticated ? (
									<AddressConfirmation />
								) : (
									<Navigate to='/login' />
								)
							}
						/>
						<Route
							path='/payment'
							element={isAuthenticated ? <Payment /> : <Navigate to='/login' />}
						/>
						<Route
							path='/account'
							element={
								isAuthenticated ? <AccountDetails /> : <Navigate to='/login' />
							}
						/>
						<Route
							path='/privacy'
							element={isAuthenticated ? <Privacy /> : <Navigate to='/login' />}
						/>
						<Route
							path='/support'
							element={isAuthenticated ? <Support /> : <Navigate to='/login' />}
						/>
						<Route
							path='/*'
							element={
								isAuthenticated ? <Navigate to='/' /> : <Navigate to='/login' />
							}
						/>
					</Routes>
					<Footer />
				</div>
			)}
		</BrowserRouter>
	)
}

export default App
