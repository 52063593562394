import { GoAlert } from 'react-icons/go'

export default function RejectedOrder() {
	return (
		<div className='flex flex-col items-center'>
			<p className='flex items-center justify-center text-white w-full py-1 bg-gray-600'>
				This order is being reviewed for you
			</p>
			<div className='flex flex-col items-center my-2 w-56 border self-center text-xs rounded-md text-center p-2 text-black bg-amber-500'>
				<GoAlert className='text-3xl my-2' />
				<p className='text-xl'>You have rejected the order</p>
				<p>You will be contacted soon by a representative.</p>
				<br />
				<p>Alternatively WhatsApp this no.</p>
				<p>084 **** 400</p>
			</div>
		</div>
	)
}
