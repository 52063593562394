export const palletteShade = [
	{ col: 'Pallette A', id: '1', quantity: 0 },
	{ col: 'Pallette B', id: '2', quantity: 0 },
	{ col: 'Pallette C', id: '3', quantity: 0 },
]
export const shirt = [
	{ col: '32', id: '1', quantity: 0 },
	{ col: '34', id: '2', quantity: 0 },
	{ col: '36', id: '3', quantity: 0 },
	{ col: '38', id: '4', quantity: 0 },
	{ col: '40', id: '5', quantity: 0 },
	{ col: '42', id: '6', quantity: 0 },
]
export const golfSize = [
	{ col: 'Small', id: '1', quantity: 0 },
	{ col: 'Medium', id: '2', quantity: 0 },
	{ col: 'Large', id: '3', quantity: 0 },
	{ col: 'X-Large', id: '4', quantity: 0 },
	{ col: 'XX-Large', id: '5', quantity: 0 },
	{ col: 'XXX-Large', id: '6', quantity: 0 },
]
export const makeupShade = [
	{ col: 'GEN 001', id: '1', quantity: 0, minQuantity: 0 },
	{ col: 'EX OO2', id: '2', quantity: 0, minQuantity: 0 },
	{ col: 'LEV 003', id: '3', quantity: 0, minQuantity: 0 },
	{ col: 'NUM 004', id: '4', quantity: 0, minQuantity: 0 },
	{ col: 'DEUT 005', id: '5', quantity: 0, minQuantity: 0 },
	{ col: 'JOSH 006', id: '6', quantity: 0, minQuantity: 0 },
	{ col: 'JUDG 007', id: '7', quantity: 0, minQuantity: 0 },
	{ col: 'RUTH 008', id: '8', quantity: 0, minQuantity: 0 },
	{ col: 'SAM 009', id: '9', quantity: 0, minQuantity: 0 },
	{ col: '1KING 010', id: '10', quantity: 0, minQuantity: 0 },
	{ col: 'JOB 011', id: '11', quantity: 0, minQuantity: 0 },
	{ col: 'JOB 018', id: '12', quantity: 0, minQuantity: 0 },
	{ col: 'PS 019', id: '13', quantity: 0, minQuantity: 0 },
	{ col: 'PROV 020', id: '14', quantity: 0, minQuantity: 0 },
	{ col: 'IS 023', id: '15', quantity: 0, minQuantity: 0 },
	{ col: 'JER 024', id: '16', quantity: 0, minQuantity: 0 },
	{ col: 'MAL 039', id: '17', quantity: 0, minQuantity: 0 },
	{ col: 'DROM 045', id: '18', quantity: 0, minQuantity: 0 },
	{ col: 'DR 048', id: '19', quantity: 0, minQuantity: 0 },
]
export const eyebrowPencil = [
	{ col: 'Pecan', id: '1', quantity: 0 },
	{ col: 'Umber', id: '2', quantity: 0 },
]
export const lipGloss = [
	{ col: 'Bongi', id: '1', quantity: 0 },
	{ col: 'Dimpho', id: '2', quantity: 0 },
	{ col: 'Mosima', id: '3', quantity: 0 },
]
export const lipstickColor = [
	{ col: 'Tumi', id: '1', quantity: 0 },
	{ col: 'Lerato', id: '2', quantity: 0 },
	{ col: 'Dipuo', id: '3', quantity: 0 },
	{ col: 'Thembi', id: '4', quantity: 0 },
	{ col: 'Lebo', id: '5', quantity: 0 },
]
export const bonzerShade = [
	{ col: 'Sunrise', id: '1', quantity: 0 },
	{ col: 'Sunset', id: '2', quantity: 0 },
	{ col: 'Naledi', id: '3', quantity: 0 },
	{ col: 'Lesedi', id: '4', quantity: 0 },
	{ col: 'Khanyi', id: '5', quantity: 0 },
]
export const capSize = [
	{ col: 'Navy blue', id: '1', quantity: 0 },
	{ col: 'Grey', id: '2', quantity: 0 },
]
