import { configureStore } from '@reduxjs/toolkit'
import userReducer from '../slices/user-slice'
import productsReducer from '../slices/product-slice'
import orderReducer from '../slices/order-slice'

export const store = configureStore({
	reducer: {
		user: userReducer,
		products: productsReducer,
		order: orderReducer,
	},
})
