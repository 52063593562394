import React from 'react'
import {
	IoAddCircleOutline,
	IoRemoveCircleOutline,
	IoTrash,
} from 'react-icons/io5'
import { useDispatch } from 'react-redux'
import { getSelectedItem } from '../context/slices/order-slice'

export default function CartItem({
	product,
	calculateDiscount,
	addQuantity,
	removeQuantity,
	deleteProduct,
	trash,
}) {
	const { image, size, name, isListed, firestoreId, quantity } = product
	const dispatch = useDispatch()

	const selectProductItems = () => {
		dispatch(
			getSelectedItem({
				product: product,
				openModal: true,
			})
		)
	}
	return (
		<div
			className={`w-full flex justify-between px-5 py-2 items-center ${
				isListed && 'border-y'
			}`}>
			<div className='flex items-center'>
				<img
					src={image}
					alt={name}
					className='h-20 w-20 p-1 mr-2 border rounded-md'
				/>
				<div className='text-sm'>
					<p>{name}</p>
					<p>{size}</p>
					<p className='text-lg'>{calculateDiscount(product)}</p>
				</div>
			</div>
			{isListed && (
				<button
					onClick={selectProductItems}
					className='text-xs self-end text-green-500'>
					click to select items
				</button>
			)}
			<div className='flex items-center space-x-2 text-xl'>
				{trash.includes(firestoreId) ? (
					<button onClick={() => deleteProduct(firestoreId)}>
						<IoTrash className='text-2xl text-red-500 border rounded-full h-8 w-8 p-1' />
					</button>
				) : (
					<button onClick={() => removeQuantity(firestoreId, quantity)}>
						<IoRemoveCircleOutline className='text-2xl' />
					</button>
				)}
				<p>{quantity}</p>
				<button onClick={() => addQuantity(firestoreId)}>
					<IoAddCircleOutline className='text-2xl' />
				</button>
			</div>
		</div>
	)
}
